<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-content">
            <div class="row">
              <div class="form-group col-md-4">
                <label>API</label>
                <select class="form-control" id="api" v-model="apiValue">
                  <option
                    v-for="(api, index) in apiData"
                    v-bind:key="index"
                    :value="api.definitionFile"
                  >{{ api.description }}</option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label>Server (http://localhost:8080/</label>
                <input type="text" placeholder="Server" v-model="server" class="form-control" />
              </div>
              <div class="form-group col-md-4">
                <label>Path (demo/test)</label>
                <input type="text" placeholder="path" v-model="path" class="form-control" />
              </div>
            </div>
            <div id="myDomId"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import Utils from "../plugin/Utils";
import "swagger-ui/dist/swagger-ui.css";
//import "swagger-ui-themes/themes/3.x/theme-outline.css"
const SwaggerUI = require("swagger-ui");
const SwaggerUIBundle = require("swagger-ui-dist").SwaggerUIBundle;
 
export default {
  data() {
    return {
      apiData: {},
      apiValue: "",
      server: "",
      path: "",
      body: {}
    };
  },
  created() {
    this.fetachApiData();
  },
  mounted() {
    var self = this;
    Utils.initalizedSelect2("#api", "");
    $("#api").change(function() {
      var document = $(this).val();
      self.loadSwaggerUi(jsyaml.load(document, { json: true }));
    });
    this.proxyServer();
  },
  methods: {
    fetachApiData: function() {
      var self = this;
      AxiosService.get(Constant.url.API_ALL).then(result => {
        self.apiData = result;
      });
    },
    loadSwaggerUi: function(document) {
      document.servers = [{ url: this.server }];
      var oldPathKey;
      _.mapKeys(document.paths, (value, key) => {
        oldPathKey = key;
      });
      document.paths = Utils.renameKey(document.paths, oldPathKey, this.path);

      var swaggerData = SwaggerUIBundle({
        dom_id: "#myDomId",
        spec: document,
        persistAuthorization: true,
        requestInterceptor: request => {
          //request.mode = "no-cors";
          if(!Utils.isEmptyStr($('.parameters-col_description input').val())) { 
             request.headers.Authorization = "Bearer "+$('.parameters-col_description input').val();
          }
          request.url = "http://localhost:8562/" + request.url;
          console.log(request);
          return request;
        },
        responseInterceptor: response => {
          console.log(response);
          return response;
        }
      });
    },
    proxyServer: async function() {
     
    }
  }
};
</script>
<style>
.swagger-ui .info .title small pre {
  margin: 6px;
  padding: 0px;
  font-family: sans-serif;
  background: white;
  color: black;
}
.swagger-ui .servers-title {
  font-size: 16px;
  font-weight: 700;
}
</style>